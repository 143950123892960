@import "./mediauser";
@import "../../../../style/common/globl.scss";

.zero {
  padding: 0 !important;
  margin: 0 !important;
}

.detail_main {
  width: 100%;
}

.detail_icon_user {
  color: $dark_blue;
  font-size: 24px;
}

.detail_btn_a {
  @extend .zero;
  width: 30%;
  border: 1px solid $dark_blue;
  outline: none;
  color: $dark_blue;
  font-size: 15px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}

.detail_btn_b {
  @extend .detail_btn_a;
  width: 30% !important;
}

.detail_btn_bbcc {
  @extend .detail_btn_a;
  border: 1px solid $dark_blue;
  width: 20%;
}

.user_check {
  display: none;
  width: 50px;
  height: 100px;
  border: 1px solid red;
}

.detail_btn_ccaa {
  @extend .detail_btn_a;
  @extend .zero;
  width: 22%;
  font-size: 12px;
  min-height: 30px;
}

.detail_btn_cbba {
  @extend .detail_btn_a;
  padding: 0 !important;
  margin: 0 !important;
  padding: 0;
  width: 20%;
}

.detail_btn_cc {
  @extend .detail_btn_a;
  width: 21%;
  font-size: 12px;
}

// start media query
@media (max-width: 576px) {
  .detail_btn_ca {
    padding: 4px;
    width: 20%;
    font-size: 12px;
  }
}
// end media query

.aaa:active {
  background-color: $dark_blue !important;
}

input[type="radio"]:checked + label {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(90deg, rgba(11, 159, 225, 1) 0%, rgba(6, 120, 193, 1) 100%);
  color: #fff;
  width: 100%;
}
.graduation + label {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(90deg, rgba(11, 159, 225, 1) 0%, rgba(6, 120, 193, 1) 100%);
  color: #fff;
  width: 100%;
}

.lw {
  width: 100% !important;
}

.sst {
  margin-top: 60px;
}

.grad {
  margin: 0;
  padding: 0;
  font-size: 12px;
  line-height: 14px;
}

.mrn {
  margin-right: 10px !important;
}

.oom {
  font-size: 13px;
  text-align: left;
  padding: 0 !important;
  margin: 0;
}

.detail_btn_cya {
  width: 33.33%;
  min-height: 30px;
  font-size: 12px;
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  padding: 2px !important;
}

.instruction_button {
  text-align: right;
  margin-top: 10px;
  button {
    font-size: 20px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    color: #eb621e;
    background: transparent;
    border-radius: 50%;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      top: 2px;
      left: 0;
      width: 100%;
      height: 100%;
      background: #e95c16;
      border-radius: 50%;
    }
  }
}

.animationOn:after {
  animation: pulse 1500ms ease-out infinite;
}

.animationOff:after {
  animation: none;
  display: none;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}

.basicdetail {
  padding-bottom: 30px;
}

.basicdetail label.control-label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 5px;
}

.basicdetail label.control-label .detail_icon_user {
  font-size: 20px;
  margin-right: 5px;
}

.basicdetail .form-group .form-control {
  border-radius: 4px;
}

.basicdetail .form-group .form-control.is-invalid {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.basicdetail .form-group .form-control:focus {
  border: 1px solid #0b9fe1;
  box-shadow: none;
}

.radio_box_container {
  display: flex;
  justify-content: space-between;
  label {
    line-height: 33px;
    width: 100%;
    cursor: pointer;
  }
  input[type="radio"]:checked + label {
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgba(11, 159, 225, 1) 0%, rgba(6, 120, 193, 1) 100%);
    color: #fff;
  }
}
